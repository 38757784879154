import React from 'react';
import Slider from './Slider';
import Events from './Events';
import './HomePage.css';
import logo_h from "../../assets/images/radioital_h.png";
import news from "../../assets/images/news1.jpg";

const HomePage = () => {
  
  const slides = [
    { image: './showcase/ital007.jpg', alt: 'Radioital 007' },
    { image: './showcase/ital008.jpg', alt: 'Radioital 008' },
    { image: './showcase/ital009.jpg', alt: 'Radioital 009' },
    { image: './showcase/ital012.jpg', alt: 'Radioital 012' },
    { image: './showcase/ital013.jpg', alt: 'Radioital 013' },
    { image: './showcase/ital014.jpg', alt: 'Radioital 014' },
    { image: './showcase/ital015.jpg', alt: 'Radioital 015' },
    { image: './showcase/ital016.jpg', alt: 'Radioital 016' },
    { image: './showcase/ital017.jpg', alt: 'Radioital 017' },
    { image: './showcase/ital040.jpg', alt: 'Radioital 040' },
    { image: './showcase/ital041.jpg', alt: 'Radioital 041' },
    { image: './showcase/ital052.jpg', alt: 'Radioital 052' },
    { image: './showcase/ital_420_2024_1.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_2.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_3.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_4.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_5.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_6.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_7.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_9.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_11.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_12.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_13.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_14.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_15.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_16.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_17.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_18.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_19.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_20.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_21.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_22.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_23.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_24.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_25.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_26.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_27.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_28.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_29.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_30.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_31.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_32.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_33.jpg', alt: 'Radioital 4/20 2024' },
    { image: './showcase/ital_420_2024_34.jpg', alt: 'Radioital 4/20 2024' },
  ];

  const evnts = [
    { image: './events/empress_menen_2025_final.jpg', alt: 'CelebraZion Natalicio #134 Empress Menen Asfaw', date: '5 de Abril 2025, 9am', place: 'Parque Entre Nubes, Bogotá - Colombia' },
    { image: './events/herbalist2025.png', alt: 'Herbalist Fest 2025', date: '20 de Abril 2025,11am', place: 'Media Torta Barrio Atanasio Girardot, Carrera 1A Este con Calle 2, Bogotá - Colombia' },
    { image: './events/rasjammy_042025.jpg', alt: 'Ras Jammy & Miniman Dub Live', date: '25 de Abril 2025, 6pm', place: 'La Kzona, Calle 15 #9-64, Bogotá - Colombia' },
    { image: './events/dbam_tunja_052025.jpg', alt: 'Mauricio Osorio DJ SET | Tunja y Sogamoso', date: '2 y 3 de Mayo 2025', place: 'Casa Enjambre(barrio la pilita), Sogamoso - Colombia' },
    { image: './events/doncarlos.jpg', alt: 'Green Valley Celebrando 20 Años', date: '15 de Mayo 2025, 6pm - 9pm', place: 'Sonora Social Club, Calle 49 # 7-27, Bogotá - Colombia' },
  ];

  

  return (
    <div className="home-page">
      <div className="home-content">
        <div className="home-parts">
          <div className="home-events">
            <h2 className='shows-title'>Events</h2>
            <div className='events_body'>
              <div className='body_in'>
                <Events slides={evnts} />
              </div>
            </div>
          </div>
          <div className="home-slides">
            <h2 className='shows-title'>Showcase</h2>
            <Slider slides={slides} />
            <div className='showcase-body'>
              <img src={logo_h} alt="logo RadioItal" />
              <h3>En la radio independiente, estamos en busqueda de personas que se identifiquen con la misión y visión; fotografos, escritores, artistas y gestores culturales que quieran ser parte de la gran familia RadioItal. </h3>
              <a className='btn_cntct' rel="noreferrer" href='https://wa.link/pe2ac7' target='_blank'>¡Contactar!</a>
            </div>
          </div>
          <div className="home-news">
            <h2 className='shows-title'>News</h2>
            <div className="news_body">
              <div className='body_in'>
                <h3>Kxng Izem: marcando la diferencia en el Reggae / Dancehall</h3>
                <img src={news} alt="News 1" />
                <p>Kxng Izem se ha consolidado como uno de los artistas emergentes más prometedores de la escena musical jamaicana. Sus letras están totalmente equilibradas con su entrega melódica, obteniendo un sonido distintivo y un mensaje muy poderoso.</p>
                <span>Tomado de: https://www.dothereggae.com/portal/kxng-izem-marcando-la-diferencia-en-el-reggae-dancehall/</span>
                <a className='btn_cntct' rel="noreferrer" href='https://www.dothereggae.com/portal/kxng-izem-marcando-la-diferencia-en-el-reggae-dancehall/' target='_blank'>Leer articulo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
